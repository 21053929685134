import React from "react";
import "./Navbar.css";
import darkLogo from "../images/julian_logo_w.svg";
import lightLogo from "../images/julian_logo_p.svg";
import DarkModeButton from "./DarkModeButton";
import { Link } from "@reach/router";

export default function Navbar() {
  const [isDarkMode, setDarkMode] = React.useState(
    document.documentElement.getAttribute("data-theme") === "dark" ||
      document.documentElement.getAttribute("data-theme") === "defaultDark"
  );

  const logo = isDarkMode ? darkLogo : lightLogo;

  return (
    // header for e-readers
    <header className="font-quicksand text-xl">
      <nav className="navbar h-20 fixed top-0 inset-x-0 shadow-xl md:text-2xl">
        <div className="flex items-center h-full px-4 max-w-4xl mx-auto">
          <div className="flex w-full justify-between">
            <div className="flex space-x-4">
              {/* logo */}
              <Link className="flex" to="/">
                <div className="flex items-center space-x-2">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: 40, height: 40 }}
                  />
                  <span className="font-bold self-center">Portfolio</span>
                </div>
              </Link>
            </div>

            {/* Dark mode switch */}
            <div>
              <DarkModeButton
                isDarkMode={isDarkMode}
                setDarkMode={setDarkMode}
              />
            </div>
					</div>
				</div>
      </nav>
    </header>
  );
}

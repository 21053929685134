export const projects = [
  {
    title: "Booomtag - Lost Scan Found",
		cardTitle: "Booomtag",
    description: "During my third year of college I had an internship at Booomtag. They are an awesome company with a vision. Their vision is \"NFC TAG TO STAY IN TOUCH WITH YOUR PRECIOUS GEAR\" They incorporate NFC tags into extreme sports gear - for example snowboards, kites and surfboards - so that they never get lost again. That's not the only purpose though, they could also serve in life threatening situations. Say you're surfboard washes up to shore and you're nowhere to be found, first responders would be able to get in touch with your ICE contacts registered at Booomtag's platform.",
    images: [
      { url: "../images/booomtaglogo.png" },
			{ url: "../images/booomtag1.jpg" },
			{ url: "../images/booomtag2.jpg" },
			{ url: "../images/booomtag3.jpg" },
			{ url: "../images/booomtag4.jpg" },
			{ url: "../images/booomtag5.jpg" },
    ],
		path: "booomtag",
		projectBackground: "../images/Animatie_Booomtag_Test1.gif",
  },
	{
    title: "NHL Stenden Robotica",
    description: "In the second year second semester we built a robot with multiple disciplines. Those disciplines were electro technicians, mechanical engineers and software engineers. We made a robot that could pick up objects, follow a blue line on the ground, cross a bridge and many more things. The robot is driven by a Raspberry pi and controlled with a remote that has an ESP32 (equivalent to a arduino but even more lightweight and minimalistic.)",
    images: [
      { url: "../images/robotica1.png" },
      { url: "../images/robotica2.png" },
      { url: "../images/robotica3.png" },
      { url: "../images/robotica4.png" },
    ],
		path: "robotica",
		previewImage: "../images/robot-preview.jpeg",
		projectBackground: "../images/robot-driving.gif",
  },
	{
    title: "Electricity Meter project",
    description: "Not yet had the time to update this...",
		path: "verbruiksmeter"
  },
];

import Title from "./Title.js";
import Button from "./Button.js";
import Social from "./Social.js";

import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Contact() {
  return (
    <div className="flex flex-col items-center">
      <Title title={"Contact"} style={{ marginTop: "2rem" }} />
      <p className="text-center max-w-sm md:max-w-md text-xl">
        Got something you want to tell me, or want help with one of your
        projects? Shoot me a message on my email or one of my socials!
      </p>
      <Button
        content={
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faExternalLink} />
            <p>Mail Me</p>
          </div>
        }
        href={"mailto:julianvv2000@gmail.com?Subject=Hello"}
        style={{ marginTop: "2rem" }}
      />
      <div className="grid gap-4 grid-cols-3 mt-8">
        <Social
          alt="Github"
          href="https://github.com/julianvv"
          icon={
            <FontAwesomeIcon 
							icon={faGithub} 
							size={"3x"} 
							className={"social"} 
						/>
          }
        />
        <Social
          alt="LinkedIn"
          href="https://www.linkedin.com/in/julian-van-veen-8b84801bb/"
          icon={
            <FontAwesomeIcon
              icon={faLinkedin}
              size={"3x"}
              className={"social"}
            />
          }
        />
        <Social
          alt="Instagram"
          href="https://instagram.com/julian_vveen"
          icon={
            <FontAwesomeIcon
              icon={faInstagram}
              size={"3x"}
              className={"social"}
            />
          }
        />
      </div>
    </div>
  );
}

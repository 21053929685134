import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import HomePage from "./screens/HomePage";
import { Router } from "@reach/router";
import PageNotFound from "./screens/PageNotFound";

const setDarkFirstTime = () => {
  document.documentElement.setAttribute("data-theme", "dark");
  document.documentElement.setAttribute("data-theme-initial", "true");
  localStorage.setItem("theme", "dark");
};

const toggleMode = (theme) => {
  if (document.documentElement.getAttribute("data-theme-initial") === "true") {
    document.documentElement.removeAttribute("data-theme-initial");
  }
  document.documentElement.setAttribute("data-theme", theme);
  localStorage.setItem("theme", theme);
};

export const toggleTheme = (e) => {
  if (e.target.checked) {
    toggleMode("dark");
  } else {
    toggleMode("light");
  }
};

export default function App() {
  const storedTheme = localStorage.getItem("theme");

  const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultDark =
    storedTheme === "dark" || (storedTheme === null && prefersDark);

  if (defaultDark) {
    setDarkFirstTime();
  }

  return (
    <React.Fragment>
      <Navbar />
      <Router>
        <PageNotFound default />
        <HomePage path="/" />
        <HomePage path="/project/:initialName" />
      </Router>
    </React.Fragment>
  );
}

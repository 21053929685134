import React, { createRef, useEffect, useState } from "react";
import Introduction from "../components/Introduction";
import Title from "../components/Title.js";
import ProjectCard from "../components/Project.js";
import Contact from "../components/Contact.js";
import loadable from "@loadable/component";
import LoadingScreen from "./LoadingScreen";
import { projects } from "../content/projects";
import PageNotFound from "./PageNotFound";

export default function HomePage({ initialName }) {
  const [currentProject, setCurrentProject] = useState(null);
  const [name, setName] = useState(initialName ?? undefined);
  const [showOverlay, setShowOverlay] = useState(false);
  const infoRef = createRef();
	
  // Check if visiting project url
  let isProjectURL = name !== undefined ? true : false;

  // Start CSS animation on info div
  const close = () => {
    infoRef.current.style.animationName = "slide-right";
  };

  // Do some logic after info div is done animating
  const handleAnimationEnd = (event) => {
    if (event.animationName === "slide-right") {
			resetStates();
      window.history.pushState({projectClosed: "true"}, "", "/");
    }
  };

  useEffect(() => {
		const listener = () => {
			resetStates();
		};

    window.addEventListener("popstate", listener);

    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, []);

  const resetStates = () => {
    setShowOverlay(false);
    setCurrentProject(null);
    setName(undefined);
  };

  useEffect(() => {
    if (isProjectURL) {
      projects.forEach((project) => {
        if (name === project.path) {
          setCurrentProject(project);
          setShowOverlay(true);
          return;
        }
      });
    }
  }, [isProjectURL, name]);

  //Check if screen exists
  if (isProjectURL && currentProject == null) {
    return <PageNotFound />;
  }

  showOverlay
    ? document.body.classList.add("no-scroll")
    : document.body.classList.remove("no-scroll");

  const AsyncProjectOverlay = loadable(
    () => import("../components/ProjectOverlay"),
    {
      fallback: LoadingScreen,
    }
  );

  return (
    <>
      <main>
        {showOverlay && (
          <AsyncProjectOverlay
            close={close}
            infoRef={infoRef}
						infoStyle={{}}
            project={currentProject}
            onAnimationEnd={handleAnimationEnd}
          />
        )}
        <div
          className={`${
            showOverlay ? "blur-filter" : ""
          } flex flex-col items-center`}
        >
          <Introduction />
          <Title
            style={{ marginTop: "4rem", marginBottom: "0.5rem" }}
            title={"Projects"}
          />
          <div className="grid place-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">
            {projects.map((project) => {
              return (
                <ProjectCard
                  key={project.path}
                  bgColor={project.bgColor}
									projectBackground={project.projectBackground ?? ""}
                  previewImage={project.previewImage ? project.previewImage : project.images ? project.images[0].url : undefined}
                  path={project.path}
                  previewTitle={project.previewTitle ?? project.title}
                  onClick={() => {
                    setCurrentProject(project);
                    setShowOverlay(true);
                    window.history.pushState(
                      { prevPage: window.location.pathname },
                      "",
                      "/project/" + project.path
                    );
                  }}
                />
              );
            })}
          </div>
          <Contact />
        </div>
      </main>
    </>
  );
}

import React from "react";
import "./Social.css";

export default function Social({ alt, href, icon }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
			{icon}
    </a>
  );
}

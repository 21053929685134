import React from "react";

export default function PageNotFound() {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center min-h-screen">
        <h1 className="font-bold font-inter gradient-text text-6xl self-center">
          404
        </h1>
        <h2 className="font-quicksand text-5xl">Page not found...</h2>
      </div>
    </div>
  );
}

import React, { memo } from "react";
import "./Project.css";

function Project({
  bgColor,
  onClick,
  previewTitle,
  previewImage,
  projectBackground,
}) {
  const previewImg = { backgroundImage: `url(${previewImage})` };
  const backgroundImage = { backgroundImage: `url(${projectBackground}), radial-gradient(circle,rgba(0,0,0,.4) 0%,rgba(0,0,0,.65) 100%)` };

  return (
    <button
      className="project-card h-48 w-72 sm:h-64 sm:w-96 rounded-xl"
      onClick={() => (onClick ? onClick() : "")}
    >
      <p className="font-bold text-2xl p-1 pb-3 text-center project-title">
        {previewTitle}
      </p>
      <div
        className="project-background"
        style={{ ...backgroundImage }}
      />
      <div
        style={{ ...previewImg }}
        className={`${
          bgColor ?? "bg-violet-700"
        } flex justify-center items-center project-preview-background`}
      />
    </button>
  );
}

export default memo(Project);

import React from "react";
import { MutatingDots } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function LoadingScreen() {
  return (
      <div className="flex justify-center">
        <MutatingDots
          color="#eaeaea"
          secondaryColor="#eaeaea"
          height={96}
          width={96}
        />
      </div>
  );
}

import React from "react";
import logo from "../images/Julian.png";
import "./Introduction.css";

export default function Introduction() {
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div className="flex items-center justify-between md:justify-center  w-full">
					{/* Intro text */}
          <div className="flex flex-col items-center text-center max-w-sm md:max-w-md">
            <h1 className="pb-2 gradient-text font-bold font-inter text-2xl md:text-4xl md:w-96">
              Hi. My Name is Julian. A Software Engineer.
            </h1>
            <div className="flex items-center pt-2 md:w-64">
              <p className="font-quicksand text-xl">
                I am a studious software engineer that's always invested to
                learn more about todays technology.
              </p>
            </div>
          </div>
          {/* Icon */}
          <img src={logo} alt="Icon" className="w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64" />
        </div>
      </div>
    </React.Fragment>
  );
}
